import React from 'react';
import {
  CodeSnippet,
  Link as DocLink,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Icon, Legend } from 'hudl-uniform-ui-components';

const legendSnippet = require('raw-loader!../../../../data/snippets/legend.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Icon: Icon, Legend: Legend }}
        code={legendSnippet}
        platform="react"
        gitHubLink="link"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="groups" types={['arrayOf shape']}>
            <Text>The items shown in the legend.</Text>
            <List type="unordered">
              <li>
                <code>category</code> - the name of a group of legend items -
                not required
              </li>
              <li>
                <code>items</code> - an array of legend items to display
                <ul style={{ marginLeft: 16 }}>
                  <li>
                    <code>symbol</code> - an element to signify the legend item
                  </li>
                  <li>
                    <code>label</code> - the text shown on the legend item
                  </li>
                </ul>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this legend in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>
              Any of the{' '}
              <DocLink href="/guidelines/space" isDesignCodeLink>
                uniCSS space classes
              </DocLink>
              .
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
